import React, { useRef, useEffect, useState } from "react";
import "./productPlaceHome.css";
import backgroundheadding from "../images/backgroundheadding.png";
import Purplebottole15 from "../images/purple-sangria-1-5.png";
import naturalflavoursback from "../images/footer.webp";

import orenge1 from "../images/orenge1.jpg";
import orenge2 from "../images/orenge2.jpg";
import orenge3 from "../images/orenge3.jpg";
import limbu1 from "../images/limbu1.jpg";
import limbu2 from "../images/limbu2.jpg";
import limbu3 from "../images/limbu3.jpg";
import grap1 from "../images/grap1.jpg";
import grap2 from "../images/grap2.jpg";
import grap3 from "../images/grap3.jpg";

import AnimatedElement from "./AnimatedElement";

function ProductPlaceHome() {
  const [offsetY, setOffsetY] = useState(0);
  const handleScroll = () => setOffsetY(window.pageYOffset);
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <div className="ProductPlaveHome-main-wrapper">
        <div className="realSangria-container">
          <div className="realSangria-whoWeAre-container">
            <div className="realSangria-whoWeAre-detailes storybehnd">
              <div className="realSangria-whoWeAre-detailes-inner">
                <div className="realSangria-whoWeAre-detailes-headding">
                  <AnimatedElement className="animate-fadein">
                    <p style={{ animationDelay: "1s" }}>Who We are</p>
                  </AnimatedElement>
                  <AnimatedElement className="animate-headding">
                    <h2>
                      <span className="vamtam-word">
                        <span
                          className="vamtam-letter blurred"
                          style={{ animationDelay: "0s" }}
                        >
                          T
                        </span>
                        <span
                          className="vamtam-letter blurred"
                          style={{ animationDelay: "0s" }}
                        >
                          h
                        </span>
                        <span
                          className="vamtam-letter blurred"
                          style={{ animationDelay: "0s" }}
                        >
                          e
                        </span>
                      </span>
                      <span className="vamtam-word">
                        <span
                          className="vamtam-letter blurred"
                          style={{ animationDelay: "0.29s" }}
                        >
                          S
                        </span>
                        <span
                          className="vamtam-letter blurred"
                          style={{ animationDelay: "0s" }}
                        >
                          t
                        </span>
                        <span
                          className="vamtam-letter blurred"
                          style={{ animationDelay: "0.15s" }}
                        >
                          o
                        </span>
                        <span
                          className="vamtam-letter blurred"
                          style={{ animationDelay: "0s" }}
                        >
                          r
                        </span>
                        <span
                          className="vamtam-letter blurred"
                          style={{ animationDelay: "0.36s" }}
                        >
                          y
                        </span>
                      </span>
                      <span className="vamtam-word">
                        <span
                          className="vamtam-letter blurred"
                          style={{ animationDelay: "0.42s" }}
                        >
                          B
                        </span>
                        <span
                          className="vamtam-letter blurred"
                          style={{ animationDelay: "0s" }}
                        >
                          e
                        </span>
                        <span
                          className="vamtam-letter blurred"
                          style={{ animationDelay: "0.22s" }}
                        >
                          h
                        </span>
                        <span
                          className="vamtam-letter blurred"
                          style={{ animationDelay: "0.32s" }}
                        >
                          i
                        </span>
                        <span
                          className="vamtam-letter blurred"
                          style={{ animationDelay: "0s" }}
                        >
                          n
                        </span>
                        <span
                          className="vamtam-letter blurred"
                          style={{ animationDelay: "0.03s" }}
                        >
                          d
                        </span>
                      </span>
                      <span className="vamtam-word">
                        <span
                          className="vamtam-letter blurred"
                          style={{ animationDelay: "0s" }}
                        >
                          O
                        </span>
                        <span
                          className="vamtam-letter blurred"
                          style={{ animationDelay: "0s" }}
                        >
                          u
                        </span>
                        <span
                          className="vamtam-letter blurred"
                          style={{ animationDelay: "0s" }}
                        >
                          r
                        </span>
                      </span>
                      <span className="vamtam-word">
                        <span
                          className="vamtam-letter blurred"
                          style={{ animationDelay: "0s" }}
                        >
                          S
                        </span>
                        <span
                          className="vamtam-letter blurred"
                          style={{ animationDelay: "0s" }}
                        >
                          a
                        </span>
                        <span
                          className="vamtam-letter blurred"
                          style={{ animationDelay: "0.09s" }}
                        >
                          n
                        </span>
                        <span
                          className="vamtam-letter blurred"
                          style={{ animationDelay: "0.06s" }}
                        >
                          g
                        </span>
                        <span
                          className="vamtam-letter blurred"
                          style={{ animationDelay: "0s" }}
                        >
                          r
                        </span>
                        <span
                          className="vamtam-letter blurred"
                          style={{ animationDelay: "0.32s" }}
                        >
                          i
                        </span>
                        <span
                          className="vamtam-letter blurred"
                          style={{ animationDelay: "0s" }}
                        >
                          a
                        </span>
                      </span>
                    </h2>
                  </AnimatedElement>
                  <img
                    src={backgroundheadding}
                    className={`background-hedding-line-image`}
                    alt="headding images"
                  />
                </div>
                <AnimatedElement className="animate-fadein">
                  <p style={{ animationDelay: "1s" }}>
                    Immerse yourself in the allure of a Mediterranean sunset,
                    where the essence of our sangria mirrors the warmth and
                    richness of the evening sky. With each sip, savor the
                    timeless tales woven into every delicious drop, a
                    celebration of tradition and togetherness.
                  </p>
                </AnimatedElement>
              </div>
            </div>
            <div className="realSangria-whoWeAre-images storybehnd">
              <AnimatedElement className="animate-image-right">
                <div className="OurSangria-detailes-image right MakeitReal"></div>
              </AnimatedElement>
            </div>
          </div>
          <div className="realSangria-whoWeAre-container">
            <div className="realSangria-whoWeAre-images">
              <div className="realSangria-whoWeAre-images-container">
                <div className="winebottome1-prent">
                  <AnimatedElement className="animate-winebottole">
                    <div className="winebottome1-prent-inner">
                      <div className="winebottome1">
                        <img src={Purplebottole15} alt="purple wine" />
                      </div>
                    </div>
                  </AnimatedElement>
                </div>
                <AnimatedElement className="animate-image-right">
                  <div className="winebottome2-prent">
                    <div className="winebottome2">
                      <img
                        src={naturalflavoursback}
                        alt="natural Flavours backIMage"
                      />
                    </div>
                  </div>
                </AnimatedElement>
              </div>
            </div>
            <div className="realSangria-whoWeAre-detailes">
              <div className="realSangria-whoWeAre-detailes-inner">
                <div className="realSangria-whoWeAre-detailes-headding">
                  <AnimatedElement className="animate-fadein">
                    <p style={{ animationDelay: "1s" }}>Natural</p>
                  </AnimatedElement>
                  <AnimatedElement className="animate-headding">
                    <h2>
                      <span className="vamtam-word">
                        <span
                          className="vamtam-letter blurred"
                          style={{ animationDelay: "0s" }}
                        >
                          1
                        </span>
                        <span
                          className="vamtam-letter blurred"
                          style={{ animationDelay: "0s" }}
                        >
                          0
                        </span>
                        <span
                          className="vamtam-letter blurred"
                          style={{ animationDelay: "0s" }}
                        >
                          0%
                        </span>
                      </span>
                      <span className="vamtam-word">
                        <span
                          className="vamtam-letter blurred"
                          style={{ animationDelay: "0.42s" }}
                        >
                          N
                        </span>
                        <span
                          className="vamtam-letter blurred"
                          style={{ animationDelay: "0s" }}
                        >
                          a
                        </span>
                        <span
                          className="vamtam-letter blurred"
                          style={{ animationDelay: "0.22s" }}
                        >
                          t
                        </span>
                        <span
                          className="vamtam-letter blurred"
                          style={{ animationDelay: "0.32s" }}
                        >
                          u
                        </span>
                        <span
                          className="vamtam-letter blurred"
                          style={{ animationDelay: "0s" }}
                        >
                          r
                        </span>
                        <span
                          className="vamtam-letter blurred"
                          style={{ animationDelay: "0.03s" }}
                        >
                          l
                        </span>
                      </span>
                      <span className="vamtam-word">
                        <span
                          className="vamtam-letter blurred"
                          style={{ animationDelay: "0.29s" }}
                        >
                          F
                        </span>
                        <span
                          className="vamtam-letter blurred"
                          style={{ animationDelay: "0s" }}
                        >
                          r
                        </span>
                        <span
                          className="vamtam-letter blurred"
                          style={{ animationDelay: "0.15s" }}
                        >
                          u
                        </span>
                        <span
                          className="vamtam-letter blurred"
                          style={{ animationDelay: "0s" }}
                        >
                          i
                        </span>
                        <span
                          className="vamtam-letter blurred"
                          style={{ animationDelay: "0.36s" }}
                        >
                          t
                        </span>
                      </span>

                      <span className="vamtam-word">
                        <span
                          className="vamtam-letter blurred"
                          style={{ animationDelay: "0s" }}
                        >
                          F
                        </span>
                        <span
                          className="vamtam-letter blurred"
                          style={{ animationDelay: "0s" }}
                        >
                          l
                        </span>
                        <span
                          className="vamtam-letter blurred"
                          style={{ animationDelay: "0.09s" }}
                        >
                          a
                        </span>
                        <span
                          className="vamtam-letter blurred"
                          style={{ animationDelay: "0.06s" }}
                        >
                          v
                        </span>
                        <span
                          className="vamtam-letter blurred"
                          style={{ animationDelay: "0s" }}
                        >
                          o
                        </span>
                        <span
                          className="vamtam-letter blurred"
                          style={{ animationDelay: "0.32s" }}
                        >
                          r
                        </span>
                        <span
                          className="vamtam-letter blurred"
                          style={{ animationDelay: "0s" }}
                        >
                          s
                        </span>
                      </span>
                    </h2>
                  </AnimatedElement>
                  <img
                    src={backgroundheadding}
                    className={`background-hedding-line-image`}
                    alt="headding images"
                  />
                </div>
                <AnimatedElement className="animate-fadein">
                  <p style={{ animationDelay: "0.5s" }}>
                    Infused with meticulously selected natural fruit essences,
                    our sangria embodies a harmonious fusion of premium
                    ingredients, delivering an unparalleled depth of flavor and
                    sophistication.
                  </p>
                </AnimatedElement>
                <AnimatedElement className="animate-fadein">
                  <p style={{ animationDelay: "0.5s" }}>
                    Each sip of our sangria is a testament to our commitment to
                    quality, as we carefully infuse it with the finest natural
                    fruit flavors, ensuring a refined and delightful drinking
                    experience.
                  </p>
                </AnimatedElement>
              </div>
            </div>
          </div>
        </div>
        <div
          className="backgroundFruites first1"
          style={{
            transform: `translateY(${offsetY * 0.15}px) `,
          }}
        >
          <img src={orenge1} alt="orenge" />
        </div>
        <div
          className="backgroundFruites first2"
          style={{
            transform: `translateY(${offsetY * 0.25}px)`,
          }}
        >
          <img src={limbu3} alt="limbu" />
        </div>
        <div
          className="backgroundFruites first3"
          style={{
            transform: `translateY(${offsetY * 0.5}px)`,
          }}
        >
          <img src={grap2} alt="grap" />
        </div>
        <div
          className="backgroundFruites first4"
          style={{
            transform: `translateY(${offsetY * 0.2}px)`,
          }}
        >
          <img src={grap1} alt="grap" />
        </div>
        <div
          className="backgroundFruites first5"
          style={{
            transform: `translateY(${offsetY * 0.25}px)  rotate(-167deg)`,
          }}
        >
          <img src={limbu1} alt="limbu" />
        </div>
        <div
          className="backgroundFruites first6"
          style={{
            transform: `translateY(${offsetY * 0.15}px)  rotate(-67deg)`,
          }}
        >
          <img src={orenge3} alt="orenge" />
        </div>
        <div
          className="backgroundFruites first7"
          style={{
            transform: `translateY(${offsetY * 0.22}px)`,
          }}
        >
          <img src={grap3} alt="grap" />
        </div>
        <div
          className="backgroundFruites first8"
          style={{
            transform: `translateY(-${offsetY * 0.15}px)  rotate(-67deg)`,
          }}
        >
          <img src={limbu2} alt="limbu" />
        </div>
        <div
          className="backgroundFruites first9"
          style={{
            transform: `translateY(-${offsetY * 0.25}px)`,
          }}
        >
          <img src={grap2} alt="grap" />
        </div>
        <div
          className="backgroundFruites first10"
          style={{
            transform: `translateY(-${offsetY * 0.15}px)  rotate(-125deg)`,
          }}
        >
          <img src={limbu3} alt="limbu" />
        </div>
        <div
          className="backgroundFruites first11"
          style={{
            transform: `translateY(${offsetY * 0.12}px) `,
          }}
        >
          <img src={orenge2} alt="orenge" />
        </div>
        <div
          className="backgroundFruites first12"
          style={{
            transform: `translateY(-${offsetY * 0.3}px)`,
          }}
        >
          <img src={grap1} alt="grap" />
        </div>
        <div
          className="backgroundFruites first13"
          style={{
            transform: `translateY(-${offsetY * 0.27}px)  rotate(-100deg)`,
          }}
        >
          <img src={orenge1} alt="orenge" />
        </div>
        <div
          className="backgroundFruites first14"
          style={{
            transform: `translateY(-${offsetY * 0.15}px)`,
          }}
        >
          <img src={limbu2} alt="limbu" />
        </div>
        <div
          className="backgroundFruites first15"
          style={{
            transform: `translateY(-${offsetY * 0.32}px)  rotate(-225deg)`,
          }}
        >
          <img src={orenge3} alt="orenge" />
        </div>
        <div
          className="backgroundFruites first16"
          style={{
            transform: `translateY(-${offsetY * 0.18}px)`,
          }}
        >
          <img src={orenge2} alt="orenge" />
        </div>
        <div
          className="backgroundFruites first17"
          style={{
            transform: `translateY(-${offsetY * 0.15}px)`,
          }}
        >
          <img src={limbu2} alt="limbu" />
        </div>
        <div
          className="backgroundFruites first18"
          style={{
            transform: `translateY(-${offsetY * 0.1}px)`,
          }}
        >
          <img src={orenge3} alt="orenge" />
        </div>
      </div>
    </>
  );
}

export default ProductPlaceHome;
