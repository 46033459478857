import React from "react";
import "./ourStore.css";
import Iframe from "react-iframe";

import PageHeroSection from "../components/pageHeroSection";
import ourStoreBackground from "../images/ourStore-background.webp";
import backgroundheadding from "../images/backgroundheadding.png";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";

function OurStores() {
  const headdingSpans = (
    <>
      <span className="vamtam-word">
        <span
          className="vamtam-letter blurred"
          style={{
            animation:
              "1.3s ease-in 0s 1 normal forwards running realSangria-blurred-letters",
          }}
        >
          F
        </span>
        <span
          className="vamtam-letter"
          style={{
            animation:
              "1.3s ease-in 0s 1 normal forwards running realSangria-blurred-letters",
          }}
        >
          i
        </span>
        <span
          className="vamtam-letter"
          style={{
            animation:
              "1.3s ease-in 0s 1 normal forwards running realSangria-blurred-letters",
          }}
        >
          n
        </span>
        <span
          className="vamtam-letter"
          style={{
            animation:
              "1.3s ease-in 0.23s 1 normal forwards running realSangria-blurred-letters",
          }}
        >
          d
        </span>
      </span>
      <span className="vamtam-word">
        <span
          className="vamtam-letter"
          style={{
            animation:
              "1.3s ease-in 0s 1 normal forwards running realSangria-blurred-letters",
          }}
        >
          a
        </span>
      </span>

      <span className="vamtam-word">
        <span
          className="vamtam-letter blurred"
          style={{
            animation:
              "1.3s ease-in 0.27s 1 normal forwards running realSangria-blurred-letters",
          }}
        >
          S
        </span>
        <span
          className="vamtam-letter"
          style={{
            animation:
              "1.3s ease-in 0s 1 normal forwards running realSangria-blurred-letters",
          }}
        >
          t
        </span>
        <span
          className="vamtam-letter"
          style={{
            animation:
              "1.3s ease-in 0s 1 normal forwards running realSangria-blurred-letters",
          }}
        >
          o
        </span>
        <span
          className="vamtam-letter"
          style={{
            animation:
              "1.3s ease-in 0.30s 1 normal forwards running realSangria-blurred-letters",
          }}
        >
          r
        </span>
        <span
          className="vamtam-letter"
          style={{
            animation:
              "1.3s ease-in 0s 1 normal forwards running realSangria-blurred-letters",
          }}
        >
          e
        </span>
      </span>
    </>
  );
  const item = {
    backimage: ourStoreBackground,
    hedding: headdingSpans,
  };
  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <title>Real Sangria - Cruz Garcia</title>
      </Helmet>
      <PageHeroSection item={item} />
      <div className="OurSangria-main-headding-wrapper">
        <div className="realSangria-container">
          <div className="OurSangria-detailes-headding">
            <p className="smallheadding">Store Locator</p>
            <h2 className="animated-headding">
              <span className="vamtam-word">
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1.12s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  L
                </span>
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  o
                </span>
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  o
                </span>
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1.33s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  k
                </span>
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1.1s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  i
                </span>
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1.1s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  n
                </span>
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  g
                </span>
              </span>
              <span className="vamtam-word">
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1.22s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  f
                </span>
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  o
                </span>
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  r
                </span>
              </span>
              <span className="vamtam-word">
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  R
                </span>
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  e
                </span>
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1.09s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  a
                </span>
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1.32s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  l
                </span>
              </span>
              <span className="vamtam-word">
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1.29s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  S
                </span>
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  a
                </span>

                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  n
                </span>
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1.36s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  g
                </span>
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  r
                </span>
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  i
                </span>
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  a
                </span>
              </span>
            </h2>
            <img
              src={backgroundheadding}
              className="background-hedding-image"
              alt="headding images"
            />
            <p className="text-center">
              Whether you have a question or a recipe to share, we're here to
              assist you. Drop us a note, and we'll get back to you within 24
              hours!
            </p>
          </div>
        </div>
      </div>
      <div className="ourstoreLocation-wrapper">
        <div className="realSangria-container">
          <div className="ifrembox">
            {/* <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d16509.800763453313!2d-80.20453967180082!3d25.76547251925134!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88d9b6840627a66b%3A0x71370843cc772b1e!2s1040%20S%20Miami%20Ave%2C%20Miami%2C%20FL%2033130%2C%20USA!5e0!3m2!1sen!2sin!4v1707915416895!5m2!1sen!2sin"
              width="600"
              height="450"
              style="border:0;"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe> */}
            <Iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d16509.800763453313!2d-80.20453967180082!3d25.76547251925134!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88d9b6840627a66b%3A0x71370843cc772b1e!2s1040%20S%20Miami%20Ave%2C%20Miami%2C%20FL%2033130%2C%20USA!5e0!3m2!1sen!2sin!4v1707915416895!5m2!1sen!2sin"
              id="myId"
              className="mapwithback"
              loading="lazy"
            ></Iframe>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default OurStores;
