import React from "react";
import "./footer.css";

function Footer() {
  return (
    <>
      <div className="realSangria-footer-wrapper">
        <div className="realSangria-footer-image">
          <div className="realSangria-container">
            <div className="realSangria-footer-detailes">
              <div className="realSangria-footer-detailes-social">
                <a>
                  <i className="fa fa-facebook-square" aria-hidden="true"></i>
                </a>
                <a>
                  <i className="fa fa-instagram" aria-hidden="true"></i>
                </a>
                <a>
                  <i className="fa fa-youtube-play" aria-hidden="true"></i>
                </a>
              </div>
              <div className="realSangria-footer-detailes-end-copyright">
                <h4>Drop Us a Line</h4>
                <p>
                  <a>hola@realsangrialuxium.com</a>
                </p>
                <p>
                  Copyright © {new Date().getFullYear()}, All rights reserved By
                  Real Sangria
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
