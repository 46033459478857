import React, { useEffect, useState } from "react";
import "./ourSangria.css";
import { Navigation, A11y, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";

import PageHeroSection from "../components/pageHeroSection";

import OurSangriaImage from "../images/ourSangria.webp";
import backgroundheadding from "../images/backgroundheadding.png";
import albertsons from "../images/albertsons-logo.jpg";
import bashas from "../images/bashas-logo.jpg";
import bevmo from "../images/bevmo-logo.jpg";
import fredmeyer from "../images/fred-meyer-logo.jpg";
import heb from "../images/heb-logo.jpg";
import hyvee from "../images/hyvee-logo.jpg";
import kroger from "../images/kroger-logo.jpg";
import meijer from "../images/meijer-logo.jpg";
import publix from "../images/publix-logo.jpg";
import target from "../images/target-logo.jpg";
import totalwine from "../images/total-wine.jpg";
import walmart from "../images/walmart-logo.jpg";
import windixie from "../images/win-dixie-logo.jpg";

import Footer from "../components/footer";
import AnimatedElement from "../components/AnimatedElement";
import { Helmet } from "react-helmet";
function OurSangria() {
  const headdingSpans = (
    <>
      <span className="vamtam-word">
        <span
          className="vamtam-letter blurred"
          style={{
            animation:
              "1.3s ease-in 0.2s 1 normal forwards running realSangria-blurred-letters",
          }}
        >
          O
        </span>
        <span
          className="vamtam-letter"
          style={{
            animation:
              "1.3s ease-in 0s 1 normal forwards running realSangria-blurred-letters",
          }}
        >
          u
        </span>
        <span
          className="vamtam-letter"
          style={{
            animation:
              "1.3s ease-in 0s 1 normal forwards running realSangria-blurred-letters",
          }}
        >
          r
        </span>
      </span>
      <span className="vamtam-word">
        <span
          className="vamtam-letter"
          style={{
            animation:
              "1.3s ease-in 0s 1 normal forwards running realSangria-blurred-letters",
          }}
        >
          S
        </span>
        <span
          className="vamtam-letter blurred"
          style={{
            animation:
              "1.3s ease-in 0.32s 1 normal forwards running realSangria-blurred-letters",
          }}
        >
          a
        </span>
        <span
          className="vamtam-letter "
          style={{
            animation:
              "1.3s ease-in 0s 1 normal forwards running realSangria-blurred-letters",
          }}
        >
          n
        </span>
        <span
          className="vamtam-letter "
          style={{
            animation:
              "1.3s ease-in 0s 1 normal forwards running realSangria-blurred-letters",
          }}
        >
          g
        </span>
        <span
          className="vamtam-letter"
          style={{
            animation:
              "1.3s ease-in 0s 1 normal forwards running realSangria-blurred-letters",
          }}
        >
          r
        </span>
        <span
          className="vamtam-letter blurred"
          style={{
            animation:
              "1.3s ease-in 0.27s 1 normal forwards running realSangria-blurred-letters",
          }}
        >
          i
        </span>
        <span
          className="vamtam-letter"
          style={{
            animation:
              "1.3s ease-in 0s 1 normal forwards running realSangria-blurred-letters",
          }}
        >
          y
        </span>
        <span
          className="vamtam-letter"
          style={{
            animation:
              "1.3s ease-in 0s 1 normal forwards running realSangria-blurred-letters",
          }}
        >
          a
        </span>
      </span>
    </>
  );
  const item = {
    backimage: OurSangriaImage,
    hedding: headdingSpans,
  };

  const [offsetY, setOffsetY] = useState(0);
  const handleScroll = () => {
    setOffsetY(window.pageYOffset);
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <title>Real Sangria - Cruz Garcia</title>
      </Helmet>
      <PageHeroSection item={item} />
      <div className="OurSangria-main-headding-wrapper">
        <div className="realSangria-container">
          <div className="OurSangria-detailes-headding">
            <p className="smallheadding">Our Sangria</p>
            <h2 className="animated-headding">
              <span className="vamtam-word">
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  W
                </span>
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  h
                </span>
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1.22s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  a
                </span>
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  t
                </span>
              </span>
              <span className="vamtam-word">
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1.15s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  i
                </span>
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  s
                </span>
              </span>
              <span className="vamtam-word">
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1.29s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  R
                </span>
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  e
                </span>

                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  á
                </span>
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1.36s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  l
                </span>
              </span>

              <span className="vamtam-word">
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  S
                </span>
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  a
                </span>
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1.09s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  n
                </span>
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1.06s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  g
                </span>
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  r
                </span>
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1.23s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  i
                </span>
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  a
                </span>
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1.32s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  ?
                </span>
              </span>
            </h2>
            <img
              src={backgroundheadding}
              className="background-hedding-image"
              alt="headding images"
            />
            <p className="text-center">
              Crafted entirely from Spanish wine and natural citrus fruit
              flavors, Reál Sangria epitomizes the essence of authentic Spanish
              Sangria. Whether it's a casual gathering or a special occasion,
              elevate the experience with the genuine taste of Reál Sangria,
              maintaining its authenticity for up to one month.
            </p>
            <AnimatedElement className="animate-fadein">
              <div className="navigationWithinPage">
                <a href="#CreateRealMoment">Real Moments</a>
                <a href="#OrderNOw">Order Now</a>
              </div>
            </AnimatedElement>
          </div>
        </div>
      </div>
      <div className="OurSangria-main-detiles-wrapper ">
        <div className="realSangria-container">
          <div className="OurSangria-detailes-wrapper left">
            <div className="OurSangria-detailes-image-parent">
              <AnimatedElement className="animate-image-left">
                <div className="OurSangria-detailes-image left popularDrinks"></div>
              </AnimatedElement>
            </div>
            <div className="OurSangria-detailes-text">
              <div className="OurSangria-detailes-text-inner">
                <AnimatedElement className="animate-fadein">
                  <h2>
                    For centuries, Sangria has stood as one of the{" "}
                    <span className="colored">most beloved Drinks</span> in
                    Spain.
                  </h2>
                </AnimatedElement>
                <AnimatedElement className="animate-fadein">
                  <p>
                    The traditional Sangria is a delightful fusion of wine and
                    fruit flavors, creating a sweet and festive libation.
                    Originating from the southern region of Spain, it was
                    initially embraced as a refreshing remedy to combat the
                    sweltering summer temperatures.
                  </p>
                </AnimatedElement>
              </div>
            </div>
          </div>
          <div className="OurSangria-detailes2-wrapper">
            <div className="OurSangria-detailes2-wrapper-inner">
              <AnimatedElement className="animate-fadein">
                <h2>
                  Other part World were introduced to sangria for the
                  <br /> first time at the{" "}
                  <span className="colored">1964 World's Fair</span>.
                </h2>
              </AnimatedElement>
              <AnimatedElement className="animate-fadein">
                <p>
                  The Spanish Pavilion delighted thirsty visitors with the sweet
                  and refreshing Sangria, marking an instant success. Following
                  its debut at the 1964 World's Fair, Sangria swiftly rose to
                  prominence, evolving from a cherished beverage primarily found
                  in Spanish restaurants to a staple drink for gatherings among
                  friends and family in the new century.
                </p>
              </AnimatedElement>
              {/* <AnimatedElement className="animate-fadein">
                <p>Whatever the occasion, keep it real with Reál Sangria.</p>
              </AnimatedElement> */}
            </div>
          </div>
          <div className="OurSangria-detailes-wrapper right">
            <div className="OurSangria-detailes-text">
              <div className="OurSangria-detailes-text-inner">
                <AnimatedElement className="animate-fadein">
                  <h2>
                    Make it <span className="colored">Real…</span>
                  </h2>
                </AnimatedElement>
                <AnimatedElement className="animate-fadein">
                  <p>
                    Reál Sangria's versatility makes it the perfect beverage for
                    any occasion.
                  </p>
                </AnimatedElement>
                <AnimatedElement className="animate-fadein">
                  <p>
                    Reál Sangria is the ideal choice for enjoying with friends
                    and family during special occasions, or as a refreshing
                    respite on a hot day.
                  </p>
                </AnimatedElement>
                <AnimatedElement className="animate-fadein">
                  <p>
                    It pairs wonderfully with tapas and serves as a delightful
                    complement to any delicious meal.
                  </p>
                </AnimatedElement>
              </div>
            </div>
            <div className="OurSangria-detailes-image-parent">
              <AnimatedElement className="animate-image-right">
                <div className="OurSangria-detailes-image right MakeitReal"></div>
              </AnimatedElement>
            </div>
          </div>
        </div>
        <div className="backgroundtexting-wrapper">
          <div className="realSangria-container">
            <div
              className="OurSangria-detailes2-wrapper gallary"
              id="CreateRealMoment"
            >
              <div className="OurSangria-detailes2-wrapper-inner">
                <AnimatedElement className="animate-fadein">
                  <h2>
                    The real moments with{" "}
                    <span className="colored">Reál Sangria</span>
                  </h2>
                </AnimatedElement>
                <AnimatedElement className="animate-fadein">
                  <p>
                    Share your Reál Moment with us on social media. <br />
                    Taag @RealSangriaLuxium with #RealMoments and #GetReal
                  </p>
                </AnimatedElement>
              </div>
            </div>
            <div className="OurSangria-detailes-wrapper">
              <div className="OurSangria-create-real-moment">
                <AnimatedElement className="animate-image-left">
                  <div className="OurSangria-create-real-moment-first"></div>
                </AnimatedElement>
                <div className="OurSangria-create-real-moment-second-perent">
                  <AnimatedElement className="animate-image-left">
                    <div className="OurSangria-create-real-moment-second"></div>
                  </AnimatedElement>
                </div>
                <AnimatedElement className="animate-image-right">
                  <div className="OurSangria-create-real-moment-third"></div>
                </AnimatedElement>
                <AnimatedElement className="animate-image-left">
                  <div className="OurSangria-create-real-moment-forth"></div>
                </AnimatedElement>
                <AnimatedElement className="animate-image-right">
                  <div className="OurSangria-create-real-moment-fifth"></div>
                </AnimatedElement>
              </div>
            </div>
            <div className="OurSangria-detailes2-wrapper gallary" id="OrderNOw">
              <div className="OurSangria-detailes2-wrapper-inner">
                <AnimatedElement className="animate-fadein">
                  <h2>
                    <span className="colored">Order Now</span> from our selling
                    partners
                  </h2>
                </AnimatedElement>
                <AnimatedElement className="animate-fadein">
                  <p>
                    Real Sangria is available nationwide. Visit your local
                    retailer
                    <br /> and #getReal today!
                  </p>
                </AnimatedElement>
              </div>
            </div>
            <AnimatedElement className="animate-fadein">
              <div className="OurSangria-detailes-wrapper">
                <div className="OurSangria-order-partners">
                  <Swiper
                    modules={[Autoplay, Navigation, A11y]}
                    spaceBetween={50}
                    slidesPerView={6}
                    autoplay={{
                      delay: 2000,
                      disableOnInteraction: false,
                    }}
                    navigation
                    breakpoints={{
                      // when window width is >= 640px
                      200: {
                        slidesPerView: 1,
                        spaceBetween: 10,
                      },
                      480: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                      },
                      640: {
                        slidesPerView: 4,
                        spaceBetween: 30,
                      },
                      1300: {
                        slidesPerView: 6,
                        spaceBetween: 40,
                      },
                    }}
                  >
                    <SwiperSlide className="slide" key="albertsons">
                      <a
                        href="https://www.albertsons.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="side-link"
                      >
                        <img src={albertsons} alt="selling partners" />
                      </a>
                    </SwiperSlide>
                    <SwiperSlide className="slide" key="bashas">
                      <a
                        href="https://www.bashas.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="side-link"
                      >
                        <img src={bashas} alt="selling partners" />
                      </a>
                    </SwiperSlide>
                    <SwiperSlide className="slide" key="bevmo">
                      <a
                        href="https://www.bevmo.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="side-link"
                      >
                        <img src={bevmo} alt="selling partners" />
                      </a>
                    </SwiperSlide>
                    <SwiperSlide className="slide" key="fredmeyer">
                      <a
                        href="https://www.fredmeyer.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="side-link"
                      >
                        <img src={fredmeyer} alt="selling partners" />
                      </a>
                    </SwiperSlide>
                    <SwiperSlide className="slide" key="heb">
                      <a
                        href="https://www.heb.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="side-link"
                      >
                        <img src={heb} alt="selling partners" />
                      </a>
                    </SwiperSlide>
                    <SwiperSlide className="slide" key="hyvee">
                      <a
                        href="https://www.hy-vee.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="side-link"
                      >
                        <img src={hyvee} alt="selling partners" />
                      </a>
                    </SwiperSlide>
                    <SwiperSlide className="slide" key="kroger">
                      <a
                        href="https://www.kroger.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="side-link"
                      >
                        <img src={kroger} alt="selling partners" />
                      </a>
                    </SwiperSlide>
                    <SwiperSlide className="slide" key="meijer">
                      <a
                        href="https://www.meijer.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="side-link"
                      >
                        <img src={meijer} alt="selling partners" />
                      </a>
                    </SwiperSlide>
                    <SwiperSlide className="slide" key="publix">
                      <a
                        href="https://www.publix.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="side-link"
                      >
                        <img src={publix} alt="selling partners" />
                      </a>
                    </SwiperSlide>
                    <SwiperSlide className="slide" key="target">
                      <a
                        href="https://www.target.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="side-link"
                      >
                        <img src={target} alt="selling partners" />
                      </a>
                    </SwiperSlide>
                    <SwiperSlide className="slide" key="totalwine">
                      <a
                        href="https://www.totalwine.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="side-link"
                      >
                        <img src={totalwine} alt="selling partners" />
                      </a>
                    </SwiperSlide>
                    <SwiperSlide className="slide" key="walmart">
                      <a
                        href="https://www.walmart.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="side-link"
                      >
                        <img src={walmart} alt="selling partners" />
                      </a>
                    </SwiperSlide>
                    <SwiperSlide className="slide" key="windixie">
                      <a
                        href="https://www.winndixie.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="side-link"
                      >
                        <img src={windixie} alt="selling partners" />
                      </a>
                    </SwiperSlide>
                  </Swiper>
                </div>
              </div>
            </AnimatedElement>
            <div className="backtextcontainer">
              <h2
                className="RSangria-backtext"
                style={{
                  transform: `translateX(${offsetY * 0.1}px) `,
                }}
              >
                Real Real Real Real
              </h2>
              <h2
                className="RSangria-backtext"
                style={{
                  transform: `translateX(-${offsetY * 0.1}px) `,
                }}
              >
                Sangria Sangria Sangria
              </h2>
              <h2
                className="RSangria-backtext"
                style={{
                  transform: `translateX(${offsetY * 0.1}px) `,
                }}
              >
                Real Real Real Real
              </h2>
              <h2
                className="RSangria-backtext"
                style={{
                  transform: `translateX(-${offsetY * 0.1}px) `,
                }}
              >
                Sangria Sangria Sangria
              </h2>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default OurSangria;
