import React, { useRef } from "react";
import "./contact.css";

import contactBackground from "../images/contact-background.jpg";
import backgroundheadding from "../images/backgroundheadding.png";

import Footer from "../components/footer";
import PageHeroSection from "../components/pageHeroSection";
import { Helmet } from "react-helmet";
function Contact() {
  const form = useRef();

  /* const sendEmail = (e) => {
    if (
      document.getElementById("name").value != "" &&
      document.getElementById("email").value != "" &&
      document.getElementById("phone").value != "" &&
      document.getElementById("subject").value != "" &&
      document.getElementById("msg").value != ""
    ) {
      document.getElementById("loddingofbutton").style.display = "block";
      document.getElementById("sendbutton").style.display = "none";
      e.preventDefault();

      emailjs
        .sendForm(
          "service_noh0qzl",
          "template_43lvi2p",
          form.current,
          "MWkjh0rRTV6YTvL_X"
        )
        .then(
          (result) => {
            console.log(result.text);
            document.getElementById("name").value = "";
            document.getElementById("email").value = "";
            document.getElementById("phone").value = "";
            document.getElementById("subject").value = "";
            document.getElementById("msg").value = "";
            document.getElementById("unsuccessmsg").style.display = "none";
            document.getElementById("emptyfuild").style.display = "none";
            document.getElementById("suceesmsg").style.display = "block";
            document.getElementById("loddingofbutton").style.display = "none";
            document.getElementById("sendbutton").style.display = "block";
          },
          (error) => {
            console.log(error.text);
            document.getElementById("suceesmsg").style.display = "none";
            document.getElementById("emptyfuild").style.display = "none";
            document.getElementById("unsuccessmsg").style.display = "block";
            document.getElementById("loddingofbutton").style.display = "none";
            document.getElementById("sendbutton").style.display = "block";
          }
        );
    } else {
      e.preventDefault();
      document.getElementById("suceesmsg").style.display = "none";
      document.getElementById("unsuccessmsg").style.display = "none";
      document.getElementById("emptyfuild").style.display = "block";
      return false;
    }
  }; */
  const headdingSpans = (
    <>
      <span className="vamtam-word">
        <span
          className="vamtam-letter"
          style={{
            animation:
              "1.3s ease-in 0s 1 normal forwards running realSangria-blurred-letters",
          }}
        >
          C
        </span>
        <span
          className="vamtam-letter blurred"
          style={{
            animation:
              "1.3s ease-in 0.32s 1 normal forwards running realSangria-blurred-letters",
          }}
        >
          o
        </span>
        <span
          className="vamtam-letter "
          style={{
            animation:
              "1.3s ease-in 0s 1 normal forwards running realSangria-blurred-letters",
          }}
        >
          n
        </span>
        <span
          className="vamtam-letter "
          style={{
            animation:
              "1.3s ease-in 0s 1 normal forwards running realSangria-blurred-letters",
          }}
        >
          t
        </span>
        <span
          className="vamtam-letter"
          style={{
            animation:
              "1.3s ease-in 0s 1 normal forwards running realSangria-blurred-letters",
          }}
        >
          a
        </span>
        <span
          className="vamtam-letter blurred"
          style={{
            animation:
              "1.3s ease-in 0.27s 1 normal forwards running realSangria-blurred-letters",
          }}
        >
          c
        </span>
        <span
          className="vamtam-letter"
          style={{
            animation:
              "1.3s ease-in 0s 1 normal forwards running realSangria-blurred-letters",
          }}
        >
          t
        </span>
      </span>
      <span className="vamtam-word">
        <span
          className="vamtam-letter blurred"
          style={{
            animation:
              "1.3s ease-in 0.2s 1 normal forwards running realSangria-blurred-letters",
          }}
        >
          U
        </span>
        <span
          className="vamtam-letter"
          style={{
            animation:
              "1.3s ease-in 0s 1 normal forwards running realSangria-blurred-letters",
          }}
        >
          s
        </span>
      </span>
    </>
  );
  const item = {
    backimage: contactBackground,
    hedding: headdingSpans,
  };
  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <title>Real Sangria - Cruz Garcia</title>
      </Helmet>
      <PageHeroSection item={item} />
      <div className="OurSangria-main-headding-wrapper">
        <div className="realSangria-container">
          <div className="OurSangria-detailes-headding">
            <p className="smallheadding">Contact us</p>
            <h2 className="animated-headding">
              <span className="vamtam-word">
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1.2s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  W
                </span>
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  e
                </span>
              </span>
              <span className="vamtam-word">
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1.12s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  L
                </span>
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  o
                </span>
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  v
                </span>
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1.33s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  e
                </span>
              </span>
              <span className="vamtam-word">
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1.2s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  t
                </span>
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  o
                </span>
              </span>
              <span className="vamtam-word">
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1.12s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  h
                </span>
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  e
                </span>
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  a
                </span>
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1.33s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  r
                </span>
              </span>
              <span className="vamtam-word">
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1.12s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  f
                </span>
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  r
                </span>
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  o
                </span>
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1.33s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  m
                </span>
              </span>
              <span className="vamtam-word">
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1.12s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  o
                </span>
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  u
                </span>
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  r
                </span>
              </span>
              <span className="vamtam-word">
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1.12s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  R
                </span>
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  e
                </span>
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  a
                </span>
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1.33s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  l
                </span>
              </span>

              <span className="vamtam-word">
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  S
                </span>
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  a
                </span>
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1.09s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  n
                </span>
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1.32s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  g
                </span>
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  r
                </span>
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  i
                </span>
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1.23s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  a
                </span>
              </span>
              <span className="vamtam-word">
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1.29s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  f
                </span>
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  r
                </span>

                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  i
                </span>
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1.36s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  e
                </span>
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  n
                </span>
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  d
                </span>
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  s
                </span>
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  !
                </span>
              </span>
            </h2>
            <img
              src={backgroundheadding}
              className="background-hedding-image"
              alt="headding images"
            />
            <p className="text-center">
              Whether you have a question or a recipe to share, we're here to
              assist you. Drop us a note, and we'll get back to you within 24
              hours!
            </p>
          </div>
        </div>
      </div>
      <section className="RSangria-send-wrapper">
        <div className="RSangria-sendcontact-box">
          <div className="RSangria-sandcontactgrid-box">
            <div className="RSangria-main-sendcontact-sec">
              <div className="RSangria-sendsetailes">
                <div className="RSangria-main-sendcontact-send">
                  <form ref={form} /* onSubmit={sendEmail} */>
                    <div className="RSangria-end-inner">
                      <div className="RSangria-send-innner-inner">
                        <label htmlFor="name">Name</label>
                        <input
                          type="text"
                          name="name"
                          id="name"
                          className="inputtag-sendcontect"
                        />
                      </div>
                      <div className="RSangria-send-innner-inner">
                        <label htmlFor="email">E-mail</label>
                        <input
                          type="text"
                          name="email"
                          id="email"
                          className="inputtag-sendcontect"
                        />
                      </div>
                    </div>
                    <div className="RSangria-end-inner">
                      <div className="RSangria-send-innner-inner">
                        <label htmlFor="phone">Mobile number</label>
                        <input
                          type="text"
                          name="phone"
                          id="phone"
                          className="inputtag-sendcontect"
                        />
                      </div>
                      <div className="RSangria-send-innner-inner">
                        <label htmlFor="subject">Subject</label>
                        <input
                          type="text"
                          name="subject"
                          id="subject"
                          className="inputtag-sendcontect"
                        />
                      </div>
                    </div>
                    <div className="RSangria-end-inner">
                      <div
                        className="RSangria-send-innner-inner textarea"
                        style={{ width: "100%" }}
                      >
                        <label htmlFor="msg">Massage</label>
                        <textarea
                          name="msg"
                          rows="5"
                          id="msg"
                          className="inputtag-sendcontect"
                        ></textarea>
                      </div>
                    </div>
                    {/* <div style={{ float: "left ", width: "80%" }}>
                      <p className="suceesmsg" id="suceesmsg">
                        Your Message is sent Successfully
                      </p>
                      <p className="unsuccessmsg" id="unsuccessmsg">
                        Something went wrong, your message isn't sent. Try
                        again!!!
                      </p>
                      <p className="unsuccessmsg" id="emptyfuild">
                        You have empty fields, fill them first.
                      </p>
                    </div> */}
                    <div className="sendbutton-wrapper">
                      <button
                        type="submit"
                        className="RSangria-sendcontact-btn"
                        style={{ float: "right " }}
                      >
                        <span className="sendbutton" id="sendbutton">
                          Send
                        </span>
                        <i
                          className="fa fa-spinner loddingofbutton"
                          id="loddingofbutton"
                        ></i>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Contact;
