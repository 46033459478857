import React, { useEffect, useState } from "react";
import "./realSangria.css";

import AnimatedElement from "./AnimatedElement";

function RealSangria() {
  const [offsetY, setOffsetY] = useState(0);
  const handleScroll = () => {
    setOffsetY(window.pageYOffset);
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <>
      <div className="RealSangria-main-wrapper">
        <div className="RealSangria-image">
          <div className="RealSangria-image-inner">
            <div className="realSangria-container">
              <div className="RealSangria-deatiles">
                <div className="RealSangria-deatiles-inner">
                  <AnimatedElement className="animate-fadein">
                    <p
                      style={{ animationDelay: "1s" }}
                      className="miniheadding"
                    >
                      Real Sangria
                    </p>
                  </AnimatedElement>
                  <AnimatedElement className="animate-headding">
                    <h2>
                      <span className="vamtam-word">
                        <span
                          className="vamtam-letter blurred"
                          style={{
                            animationDelay: "0.2s",
                          }}
                        >
                          I
                        </span>
                        <span
                          className="vamtam-letter blurred"
                          style={{
                            animationDelay: "0s",
                          }}
                        >
                          t'
                        </span>
                        <span
                          className="vamtam-letter blurred"
                          style={{
                            animationDelay: "0s",
                          }}
                        >
                          s
                        </span>
                      </span>
                      <span className="vamtam-word">
                        <span
                          className="vamtam-letter blurred"
                          style={{
                            animationDelay: "0s",
                          }}
                        >
                          H
                        </span>
                        <span
                          className="vamtam-letter blurred"
                          style={{
                            animationDelay: "0.32s",
                          }}
                        >
                          i
                        </span>
                        <span
                          className="vamtam-letter blurred"
                          style={{
                            animationDelay: "0s",
                          }}
                        >
                          p
                        </span>
                      </span>
                      <span className="vamtam-word">
                        <span
                          className="vamtam-letter blurred"
                          style={{
                            animationDelay: "0s",
                          }}
                        >
                          T
                        </span>
                        <span
                          className="vamtam-letter blurred"
                          style={{
                            animationDelay: "0s",
                          }}
                        >
                          o
                        </span>
                      </span>
                      <span className="vamtam-word">
                        <span
                          className="vamtam-letter blurred"
                          style={{
                            animationDelay: "0.27s",
                          }}
                        >
                          S
                        </span>
                        <span
                          className="vamtam-letter blurred"
                          style={{
                            animationDelay: "0s",
                          }}
                        >
                          i
                        </span>
                        <span
                          className="vamtam-letter blurred"
                          style={{
                            animationDelay: "0s",
                          }}
                        >
                          p
                        </span>
                      </span>
                    </h2>
                  </AnimatedElement>
                  <AnimatedElement className="animate-fadein">
                    <p style={{ animationDelay: "1s" }}>
                      For centuries, sangria has remained a beloved beverage in
                      Spain. Rooted in the southern region of the country,
                      traditional sangria comprises Spanish red wine infused
                      with fruity flavors. Originally conceived as a refreshing
                      antidote to the sweltering summer temperatures.
                    </p>
                  </AnimatedElement>
                </div>
              </div>
            </div>
          </div>
          <div className="RealSangria-backgroundnameline">
            <h2
              style={{
                transform: `translateX(${offsetY * -0.2}px) `,
              }}
            >
              Real Sangria Real Sangria Real Sangria Real Sangria Real Sangria
              Real Sangria
            </h2>
          </div>
        </div>
      </div>
      <div className="realsangria-productspage">
        <div className="realsangria-productspage-backimage">
          <AnimatedElement className="animate-fadein">
            <h4>WE'RE PROUD OF CREATING LEGENDARY SANGRIA</h4>
          </AnimatedElement>
        </div>
      </div>
    </>
  );
}

export default RealSangria;
