import React, { useState } from "react";
import logo from "../images/Logo-White.svg";
import { NavLink } from "react-router-dom";

import "./navbar.css";

function Navbar() {
  const [Click, setClick] = useState(false);
  const handelClick = () => setClick(!Click);
  const closemibilemanu = () => setClick(false);

  return (
    <>
      <div id="top" className="RealSangria-navbar-wrapper">
        <div className="RealSangria-links-container-one RealSangria-mobile-link">
          <ul className="RealSangria-nav-container">
            <li className="RealSangria-nav-item">
              <NavLink
                to="/"
                onClick={closemibilemanu}
                className={({ isActive }) =>
                  isActive
                    ? "active RealSangria-nav-link"
                    : "RealSangria-nav-link"
                }
                style={{
                  animation:
                    "1.3s ease-in 0.2s 1 normal forwards running realSangria-blurred-letters",
                }}
              >
                Home
              </NavLink>
            </li>
            <li className="RealSangria-nav-item">
              <NavLink
                to="/ourSangria"
                onClick={closemibilemanu}
                className={({ isActive }) =>
                  isActive
                    ? "active RealSangria-nav-link"
                    : "RealSangria-nav-link"
                }
                style={{
                  animation:
                    "1.3s ease-in 0.42s 1 normal forwards running realSangria-blurred-letters",
                }}
              >
                Our Sangria
              </NavLink>
            </li>
            <li className="RealSangria-nav-item">
              <NavLink
                to="/products"
                onClick={closemibilemanu}
                className={({ isActive }) =>
                  isActive
                    ? "active RealSangria-nav-link"
                    : "RealSangria-nav-link"
                }
                style={{
                  animation:
                    "1.3s ease-in 0.3s 1 normal forwards running realSangria-blurred-letters",
                }}
              >
                Products
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="RealSangria-logo-container">
          <NavLink to="/">
            <img
              src={logo}
              className="RealSangria-logo"
              alt="real sangria"
              height={"120"}
              style={{
                animation:
                  "1.3s ease-in 0s 1 normal forwards running realSangria-blurred-letters",
              }}
            />
          </NavLink>
        </div>
        <div className="RealSangria-links-container-two RealSangria-mobile-link">
          <ul className="RealSangria-nav-container">
            <li className="RealSangria-nav-item">
              <NavLink
                to="/hipToShip"
                onClick={closemibilemanu}
                className={({ isActive }) =>
                  isActive
                    ? "active RealSangria-nav-link"
                    : "RealSangria-nav-link"
                }
                style={{
                  animation:
                    "1.3s ease-in 0.5s 1 normal forwards running realSangria-blurred-letters",
                }}
              >
                Hip To Ship
              </NavLink>
            </li>
            <li className="RealSangria-nav-item">
              <NavLink
                to="/ourstores"
                onClick={closemibilemanu}
                className={({ isActive }) =>
                  isActive
                    ? "active RealSangria-nav-link"
                    : "RealSangria-nav-link"
                }
                style={{
                  animation:
                    "1.3s ease-in 0.1s 1 normal forwards running realSangria-blurred-letters",
                }}
              >
                Our Stores
              </NavLink>
            </li>
            <li className="RealSangria-nav-item">
              <NavLink
                to="/Contact"
                onClick={closemibilemanu}
                className={({ isActive }) =>
                  isActive
                    ? "active RealSangria-nav-link"
                    : "RealSangria-nav-link"
                }
                style={{
                  animation:
                    "1.3s ease-in 0.35s 1 normal forwards running realSangria-blurred-letters",
                }}
              >
                Contact
              </NavLink>
            </li>
          </ul>
        </div>
        <div
          className={
            Click
              ? "RealSangria-links-container-mobile mobileDisplay"
              : "RealSangria-links-container-mobile"
          }
        >
          <ul className="RealSangria-nav-container">
            <li className="RealSangria-nav-item">
              <NavLink
                to="/"
                onClick={closemibilemanu}
                className={({ isActive }) =>
                  isActive
                    ? "active RealSangria-nav-link"
                    : "RealSangria-nav-link"
                }
              >
                Home
              </NavLink>
            </li>
            <li className="RealSangria-nav-item">
              <NavLink
                to="/ourSangria"
                onClick={closemibilemanu}
                className={({ isActive }) =>
                  isActive
                    ? "active RealSangria-nav-link"
                    : "RealSangria-nav-link"
                }
              >
                Our Sangria
              </NavLink>
            </li>
            <li className="RealSangria-nav-item">
              <NavLink
                to="/products"
                onClick={closemibilemanu}
                className={({ isActive }) =>
                  isActive
                    ? "active RealSangria-nav-link"
                    : "RealSangria-nav-link"
                }
              >
                Products
              </NavLink>
            </li>

            <li className="RealSangria-nav-item">
              <NavLink
                to="/hipToShip"
                onClick={closemibilemanu}
                className={({ isActive }) =>
                  isActive
                    ? "active RealSangria-nav-link"
                    : "RealSangria-nav-link"
                }
              >
                Hip To Ship
              </NavLink>
            </li>
            <li className="RealSangria-nav-item">
              <NavLink
                to="/ourstores"
                onClick={closemibilemanu}
                className={({ isActive }) =>
                  isActive
                    ? "active RealSangria-nav-link"
                    : "RealSangria-nav-link"
                }
              >
                Our Stores
              </NavLink>
            </li>
            <li className="RealSangria-nav-item">
              <NavLink
                to="/Contact"
                onClick={closemibilemanu}
                className={({ isActive }) =>
                  isActive
                    ? "active RealSangria-nav-link"
                    : "RealSangria-nav-link"
                }
              >
                Contact
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="RealSangria-hamburder-menu" onClick={handelClick}>
          <i className={Click ? "fa fa-2x fa-times" : "fa fa-2x fa-bars"} />
        </div>
      </div>
    </>
  );
}

export default Navbar;
