import React, { useState, useEffect } from "react";
import "./products.css";
import PageHeroSection from "../components/pageHeroSection";
import productBackground from "../images/product-background.webp";
import backgroundheadding from "../images/backgroundheadding.png";

import Footer from "../components/footer";
import { HashLink } from "react-router-hash-link";
import { Helmet } from "react-helmet";
function Products() {
  const headdingSpans = (
    <>
      <span className="vamtam-word">
        <span
          className="vamtam-letter blurred"
          style={{
            animation:
              "1.3s ease-in 0.2s 1 normal forwards running realSangria-blurred-letters",
          }}
        >
          O
        </span>
        <span
          className="vamtam-letter"
          style={{
            animation:
              "1.3s ease-in 0s 1 normal forwards running realSangria-blurred-letters",
          }}
        >
          u
        </span>
        <span
          className="vamtam-letter"
          style={{
            animation:
              "1.3s ease-in 0s 1 normal forwards running realSangria-blurred-letters",
          }}
        >
          r
        </span>
      </span>
      <span className="vamtam-word">
        <span
          className="vamtam-letter"
          style={{
            animation:
              "1.3s ease-in 0s 1 normal forwards running realSangria-blurred-letters",
          }}
        >
          P
        </span>
        <span
          className="vamtam-letter blurred"
          style={{
            animation:
              "1.3s ease-in 0.32s 1 normal forwards running realSangria-blurred-letters",
          }}
        >
          r
        </span>
        <span
          className="vamtam-letter "
          style={{
            animation:
              "1.3s ease-in 0s 1 normal forwards running realSangria-blurred-letters",
          }}
        >
          o
        </span>
        <span
          className="vamtam-letter "
          style={{
            animation:
              "1.3s ease-in 0s 1 normal forwards running realSangria-blurred-letters",
          }}
        >
          d
        </span>
        <span
          className="vamtam-letter"
          style={{
            animation:
              "1.3s ease-in 0s 1 normal forwards running realSangria-blurred-letters",
          }}
        >
          u
        </span>
        <span
          className="vamtam-letter blurred"
          style={{
            animation:
              "1.3s ease-in 0.27s 1 normal forwards running realSangria-blurred-letters",
          }}
        >
          c
        </span>
        <span
          className="vamtam-letter"
          style={{
            animation:
              "1.3s ease-in 0s 1 normal forwards running realSangria-blurred-letters",
          }}
        >
          t
        </span>
        <span
          className="vamtam-letter"
          style={{
            animation:
              "1.3s ease-in 0s 1 normal forwards running realSangria-blurred-letters",
          }}
        >
          s
        </span>
      </span>
    </>
  );
  const item = {
    backimage: productBackground,
    hedding: headdingSpans,
  };
  const [offsetY, setOffsetY] = useState(0);
  const [trigger, settrigger] = useState(false);
  const [triggersleepy, settriggersleepy] = useState(false);
  const handleScroll = () => {
    if (window.scrollY > 500) {
      settrigger(true);
    }
    if (window.scrollY < 650) {
      settrigger(false);
    }
    if (window.scrollY > 650) {
      settriggersleepy(true);
      console.log(true);
    }
    if (window.scrollY < 650) {
      settriggersleepy(false);
      console.log(window.scrollY, false);
    }
    setOffsetY(window.pageYOffset);
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <title>Real Sangria - Cruz Garcia</title>
      </Helmet>
      <PageHeroSection item={item} />
      <div className="OurSangria-main-headding-wrapper">
        <div className="realSangria-container">
          <div className="OurSangria-detailes-headding">
            <p className="smallheadding">Product Range</p>
            <h2 className="animated-headding">
              <span className="vamtam-word">
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  O
                </span>
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  u
                </span>
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1.22s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  r
                </span>
              </span>

              <span className="vamtam-word">
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  P
                </span>
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  r
                </span>
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1.09s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  o
                </span>
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1.32s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  d
                </span>
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  u
                </span>
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  c
                </span>
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1.23s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  t
                </span>
              </span>
              <span className="vamtam-word">
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1.29s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  R
                </span>
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  a
                </span>

                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  n
                </span>
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1.36s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  g
                </span>
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  e
                </span>
              </span>
            </h2>
            <img
              src={backgroundheadding}
              className="background-hedding-image"
              alt="headding images"
            />
            <p className="text-center">
              Cruz Garcia's Real Sangria is the perfect choice whenever you seek
              a sweet and refreshing beverage with a low ABV, whether you're at
              home or on the go. Available in red, white and purple varieties,
              and offered in several sizes to suit every drinking occasion, it's
              a versatile option for any setting.
            </p>
          </div>
        </div>
      </div>
      <div className="product-wrapres">
        <section className="hero" id="section-0">
          <div className="hero-inner">
            <div>
              <div className="bottlegroup">
                <figure className="bottole-figure"></figure>
                <figure className="bottole-750-figure"></figure>
                <figure className="bottole-tin-figure"></figure>
                <figure className="bottole-box-figure"></figure>
              </div>
              <figure
                className="backgroundeimage-figure"
                style={
                  trigger
                    ? { clipPath: `inset(0)` }
                    : { clipPath: `inset(0 0 0 100%)` }
                }
              ></figure>
              <div className="hero__title">
                <div className="realSangria-whoWeAre--headding">
                  <h2
                    style={triggersleepy ? { opacity: `1` } : { opacity: `0` }}
                  >
                    Purple Sangria
                  </h2>
                  <p
                    style={triggersleepy ? { opacity: `1` } : { opacity: `0` }}
                  >
                    <b>7.5 % Alcohol</b>
                  </p>
                  <p
                    style={triggersleepy ? { opacity: `1` } : { opacity: `0` }}
                  >
                    With a base of purple Spanish wine, Reál Sangria boasts a
                    rich ruby-purple hue. Its flavor profile is sweet and
                    fruit-forward, accentuated by refreshing citrus notes.
                    Offering a full and round taste, it's characterized by its
                    delightful sweetness and prominent fruitiness.
                  </p>
                  <ul
                    style={triggersleepy ? { opacity: `1` } : { opacity: `0` }}
                  >
                    <li>Purple Sangria 1.5 Lt</li>
                    <li>Purple Sangria 750 Ml</li>
                    <li>Purple Sangria 250 Ml</li>
                    <li>Purple Sangria 3 Lt</li>
                  </ul>
                  <img
                    src={backgroundheadding}
                    className={`background-hedding-line-image`}
                    alt="headding images"
                  />
                </div>
              </div>
              <div className="backgroundnamescroll">
                <h2
                  style={{
                    transform: `translateX(${offsetY * 0.2}px) `,
                  }}
                >
                  Purple Sangria Purple Sangria Purple Sangria
                </h2>
              </div>
            </div>
          </div>
        </section>
        <section className="hero" id="section-1">
          <div className="hero-inner">
            <div>
              <div className="bottlegroup">
                <figure className="bottole-figure"></figure>
                <figure className="bottole-750-figure"></figure>
                <figure className="bottole-tin-figure"></figure>
                <figure className="bottole-box-figure"></figure>
              </div>
              <figure className="backgroundeimage-figure"></figure>
              <div className="hero__title">
                <div className="realSangria-whoWeAre--headding">
                  <h2>White Sangria</h2>
                  <p>
                    <b>8.0 % Alcohol</b>
                  </p>
                  <p>
                    Crafted with a base of white Spanish wine, Reál Sangria
                    exhibits a delicate pale straw color. Its flavor profile is
                    sweet and fruit-forward, enhanced by vibrant citrus notes.
                    This variant offers a light and sweet taste, with a
                    prominent fruit-forward character that delights the palate.
                  </p>
                  <ul>
                    <li>White Sangria 1.5 Lt</li>
                    <li>White Sangria 750 Ml</li>
                    <li>White Sangria 250 Ml</li>
                    <li>White Sangria 3 Lt</li>
                  </ul>
                  <img
                    src={backgroundheadding}
                    className={`background-hedding-line-image`}
                    alt="headding images"
                  />
                </div>
              </div>
              <div className="backgroundnamescroll white">
                <h2
                  style={{
                    transform: `translateX(${offsetY * 0.15}px) `,
                  }}
                >
                  White Sangria White Sangria White Sangria White Sangria
                </h2>
              </div>
            </div>
          </div>
        </section>
        <section className="hero" id="section-2">
          <div className="hero-inner">
            <div>
              <div className="bottlegroup">
                <figure className="bottole-figure"></figure>
                <figure className="bottole-750-figure"></figure>
                <figure className="bottole-tin-figure"></figure>
                <figure className="bottole-box-figure"></figure>
              </div>
              <figure className="backgroundeimage-figure"></figure>
              <div className="hero__title">
                <div className="realSangria-whoWeAre--headding">
                  <h2>Red Sangria</h2>
                  <p>
                    <b>7.5 % Alcohol</b>
                  </p>
                  <p>
                    Crafted from red Spanish wine, Reál Sangria boasts a
                    ruby-red color and a sweet, fruit-forward taste with citrus
                    notes. It offers a full, round flavor that's both sweet and
                    fruit-forward.
                  </p>
                  <ul>
                    <li>Red Sangria 1.5 Lt</li>
                    <li>Red Sangria 750 Ml</li>
                    <li>Red Sangria 250 Ml</li>
                    <li>Red Sangria 3 Lt</li>
                  </ul>
                  <img
                    src={backgroundheadding}
                    className={`background-hedding-line-image`}
                    alt="headding images"
                  />
                </div>
              </div>
              <div className="backgroundnamescroll red">
                <h2
                  style={{
                    transform: `translateX(${offsetY * 0.1}px) `,
                  }}
                >
                  Red Sangria Red Sangria Red Sangria
                </h2>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="ordernow-productspage">
        <div className="ordernow-productspage-backimage">
          <h4>Réal Sangria Is Ready To Drink Just Chill And Enjoy!</h4>
          <HashLink to="/ourSangria#OrderNOw" className="ordernowbutton">
            Buy Now
          </HashLink>
          <div className="ordernow-productspage-backimage-overlay"></div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Products;
