import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import "./model.css";

function ModelImage({ isOpen, closeModalIMage, imageUrl, headding }) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalIsOpening, setModalIsOpening] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setModalIsOpen(true);
      setTimeout(() => setModalIsOpening(true), 500);
    } else {
      setModalIsOpening(false);
      setTimeout(() => setModalIsOpen(false), 500);
    }
  }, [isOpen]);

  function afterClose() {
    if (!modalIsOpen) {
      // Ensure a smooth transition for the overlay
      document.body.style.overflow = "";
    }
  }

  return (
    <>
      <div>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModalIMage}
          onAfterClose={afterClose}
          contentLabel="Example Modal"
          closeTimeoutMS={300}
          className={
            modalIsOpening
              ? "modal-transition-here imagemodel open"
              : "modal-transition-here imagemodel"
          }
        >
          <div className="model-wrapper">
            <button onClick={closeModalIMage} className="close-button">
              &times;
            </button>
          </div>
          <div className="model-image-wrapper">
            <img src={imageUrl} alt="Selected" />
            <p className="text-center">{headding}</p>
          </div>
        </Modal>
      </div>
    </>
  );
}

export default ModelImage;
