import React from "react";
import "./pageHeroSection.css";

function PageHeroSection({ item }) {
  return (
    <>
      <div className="PageHeroSection-wrapper">
        <div className="PageHeroSection-mainimmage-container">
          <h1>{item.hedding}</h1>
          <div
            className="PageHeroSection-mainimmage"
            style={{
              backgroundImage: `url(${item.backimage})`,
              animation:
                "1s ease-in 0s 1 normal forwards running realSangria-blurred-letters",
            }}
          ></div>
          <div
            className="realSangria-heroOverlay"
            style={{
              animation:
                "1s ease-in 0s 1 normal forwards running realSangria-blurred-letters",
            }}
          ></div>
        </div>
      </div>
    </>
  );
}

export default PageHeroSection;
