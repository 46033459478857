import React, { useRef, useState, useEffect } from "react";

const AnimatedElement = ({ children, className }) => {
  const animatedRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const options = {
      threshold: 0.1,
    };

    const handleIntersection = (entries, observer) => {
      entries.forEach((entry) => {
        setIsVisible(entry.isIntersecting);
      });
    };

    const observer = new IntersectionObserver(handleIntersection, options);

    if (animatedRef.current) {
      observer.observe(animatedRef.current);
    }

    return () => {
      if (animatedRef.current) {
        observer.unobserve(animatedRef.current);
      }
    };
  }, []);

  return (
    <div
      ref={animatedRef}
      className={`animated-element-wrapper ${isVisible ? className : ""}`}
    >
      {children}
    </div>
  );
};

export default AnimatedElement;
