import Navbar from "./components/navbar";
import Herosection from "./components/herosection";
import Footer from "./components/footer";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import ScrollToTop from "./components/ScrollToTop";
import { Helmet } from "react-helmet";

import PageNotFound from "./components/PageNotFound";

import ProductPlaceHome from "./components/productPlaceHome";
import OurSangria from "./pages/ourSangria";
import Products from "./pages/products";
import HipRecipes from "./pages/hipRecipes";
import Contact from "./pages/contact";
import OurStores from "./pages/ourStores";
import RealSangria from "./components/realSangria";

function App() {
  return (
    <>
      <Router>
        {/* <ScrollToTop /> */}
        <Navbar />
        <Routes>
          <Route path="/Contact" element={<Contact />} />

          <Route path="/ourSangria" element={<OurSangria />} />
          <Route path="/products" element={<Products />} />
          <Route path="/hipToShip" element={<HipRecipes />} />
          <Route path="/ourstores" element={<OurStores />} />

          <Route path="/" exect element={<Home />} />
          <Route path="*" exect element={<PageNotFound />} />
        </Routes>
      </Router>
    </>
  );
}

function Home() {
  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <title>Real Sangria - Cruz Garcia</title>
      </Helmet>
      <Herosection />
      {/* <Product /> */}
      <ProductPlaceHome />
      <RealSangria />
      <Footer />
    </>
  );
}

export default App;
