import React, { useRef, useEffect, useState } from "react";
import "./herosection.css";
import WineWave3 from "../images/winewave3.png";
import WineWave4 from "../images/winewave4.png";
function Herosection() {
  const [offsetY, setOffsetY] = useState(0);
  const handleScroll = () => setOffsetY(window.pageYOffset);
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const animatedRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const options = {
      threshold: 0.5, // Adjust this value based on when you want the animation to start
    };

    const handleIntersection = (entries, observer) => {
      entries.forEach((entry) => {
        setIsVisible(entry.isIntersecting);
      });
    };

    const observer = new IntersectionObserver(handleIntersection, options);

    if (animatedRef.current) {
      observer.observe(animatedRef.current);
    }

    // Cleanup the observer when the component unmounts
    return () => {
      if (animatedRef.current) {
        observer.unobserve(animatedRef.current);
      }
    };
  }, []);

  return (
    <>
      <div className="herosection-wrapper">
        <section id="herosection" className="realSangria-herosection-wrapper">
          <div className="realSangria-background">
            <div className="realSangria-container">
              <h1
                style={{
                  transform: `translateY(calc(-50% + ${
                    offsetY * 0.15
                  }px)) translateX(-50%)`,
                }}
              >
                <span className="vamtam-word">
                  <span
                    className="vamtam-letter blurred"
                    style={{
                      animation:
                        "1.3s ease-in 0.1s 1 normal forwards running realSangria-blurred-letters",
                    }}
                  >
                    1
                  </span>
                  <span
                    className="vamtam-letter"
                    style={{
                      animation:
                        "1.3s ease-in 0s 1 normal forwards running realSangria-blurred-letters",
                    }}
                  >
                    0
                  </span>
                  <span
                    className="vamtam-letter"
                    style={{
                      animation:
                        "1.3s ease-in 0.05s 1 normal forwards running realSangria-blurred-letters",
                    }}
                  >
                    0
                  </span>
                  <span
                    className="vamtam-letter"
                    style={{
                      animation:
                        "1.3s ease-in 0.4s 1 normal forwards running realSangria-blurred-letters",
                    }}
                  >
                    %
                  </span>
                </span>
                <span className="vamtam-word">
                  <span
                    className="vamtam-letter"
                    style={{
                      animation:
                        "1.3s ease-in 0.2s 1 normal forwards running realSangria-blurred-letters",
                    }}
                  >
                    S
                  </span>
                  <span
                    className="vamtam-letter"
                    style={{
                      animation:
                        "1.3s ease-in 0s 1 normal forwards running realSangria-blurred-letters",
                    }}
                  >
                    p
                  </span>
                  <span
                    className="vamtam-letter"
                    style={{
                      animation:
                        "1.3s ease-in 0s 1 normal forwards running realSangria-blurred-letters",
                    }}
                  >
                    a
                  </span>
                  <span
                    className="vamtam-letter"
                    style={{
                      animation:
                        "1.3s ease-in 0s 1 normal forwards running realSangria-blurred-letters",
                    }}
                  >
                    n
                  </span>
                  <span
                    className="vamtam-letter"
                    style={{
                      animation:
                        "1.3s ease-in 0.32s 1 normal forwards running realSangria-blurred-letters",
                    }}
                  >
                    i
                  </span>
                  <span
                    className="vamtam-letter"
                    style={{
                      animation:
                        "1.3s ease-in 0s 1 normal forwards running realSangria-blurred-letters",
                    }}
                  >
                    s
                  </span>
                  <span
                    className="vamtam-letter"
                    style={{
                      animation:
                        "1.3s ease-in 0s 1 normal forwards running realSangria-blurred-letters",
                    }}
                  >
                    h
                  </span>
                </span>
                <span className="vamtam-word">
                  <span
                    className="vamtam-letter"
                    style={{
                      animation:
                        "1.3s ease-in 0.3s 1 normal forwards running realSangria-blurred-letters",
                    }}
                  >
                    W
                  </span>
                  <span
                    className="vamtam-letter"
                    style={{
                      animation:
                        "1.3s ease-in 0.1s 1 normal forwards running realSangria-blurred-letters",
                    }}
                  >
                    i
                  </span>
                  <span
                    className="vamtam-letter"
                    style={{
                      animation:
                        "1.3s ease-in 0.1s 1 normal forwards running realSangria-blurred-letters",
                    }}
                  >
                    n
                  </span>
                  <span
                    className="vamtam-letter"
                    style={{
                      animation:
                        "1.3s ease-in 0.2s 1 normal forwards running realSangria-blurred-letters",
                    }}
                  >
                    e
                  </span>
                </span>
                <span className="vamtam-word">
                  <span
                    className="vamtam-letter"
                    style={{
                      animation:
                        "1.3s ease-in 0.32s 1 normal forwards running realSangria-blurred-letters",
                    }}
                  >
                    A
                  </span>
                  <span
                    className="vamtam-letter"
                    style={{
                      animation:
                        "1.3s ease-in 0s 1 normal forwards running realSangria-blurred-letters",
                    }}
                  >
                    n
                  </span>
                  <span
                    className="vamtam-letter"
                    style={{
                      animation:
                        "1.3s ease-in 0s 1 normal forwards running realSangria-blurred-letters",
                    }}
                  >
                    d
                  </span>
                </span>
                <span className="vamtam-word">
                  <span
                    className="vamtam-letter"
                    style={{
                      animation:
                        "1.3s ease-in 0.22s 1 normal forwards running realSangria-blurred-letters",
                    }}
                  >
                    N
                  </span>
                  <span
                    className="vamtam-letter"
                    style={{
                      animation:
                        "1.3s ease-in 0s 1 normal forwards running realSangria-blurred-letters",
                    }}
                  >
                    a
                  </span>
                  <span
                    className="vamtam-letter"
                    style={{
                      animation:
                        "1.3s ease-in 0s 1 normal forwards running realSangria-blurred-letters",
                    }}
                  >
                    t
                  </span>
                  <span
                    className="vamtam-letter"
                    style={{
                      animation:
                        "1.3s ease-in 0.42s 1 normal forwards running realSangria-blurred-letters",
                    }}
                  >
                    u
                  </span>
                  <span
                    className="vamtam-letter"
                    style={{
                      animation:
                        "1.3s ease-in 0.1s 1 normal forwards running realSangria-blurred-letters",
                    }}
                  >
                    r
                  </span>
                  <span
                    className="vamtam-letter"
                    style={{
                      animation:
                        "1.3s ease-in 0.1s 1 normal forwards running realSangria-blurred-letters",
                    }}
                  >
                    a
                  </span>
                  <span
                    className="vamtam-letter"
                    style={{
                      animation:
                        "1.3s ease-in 0s 1 normal forwards running realSangria-blurred-letters",
                    }}
                  >
                    l
                  </span>
                </span>
                <span className="vamtam-word">
                  <span
                    className="vamtam-letter"
                    style={{
                      animation:
                        "1.3s ease-in 0.44s 1 normal forwards running realSangria-blurred-letters",
                    }}
                  >
                    F
                  </span>
                  <span
                    className="vamtam-letter"
                    style={{
                      animation:
                        "1.3s ease-in 0s 1 normal forwards running realSangria-blurred-letters",
                    }}
                  >
                    r
                  </span>
                  <span
                    className="vamtam-letter"
                    style={{
                      animation:
                        "1.3s ease-in 0s 1 normal forwards running realSangria-blurred-letters",
                    }}
                  >
                    u
                  </span>
                  <span
                    className="vamtam-letter"
                    style={{
                      animation:
                        "1.3s ease-in 0.3s 1 normal forwards running realSangria-blurred-letters",
                    }}
                  >
                    i
                  </span>
                  <span
                    className="vamtam-letter"
                    style={{
                      animation:
                        "1.3s ease-in 0s 1 normal forwards running realSangria-blurred-letters",
                    }}
                  >
                    t
                  </span>
                </span>
                <span className="vamtam-word">
                  <span
                    className="vamtam-letter"
                    style={{
                      animation:
                        "1.3s ease-in 0s 1 normal forwards running realSangria-blurred-letters",
                    }}
                  >
                    F
                  </span>
                  <span
                    className="vamtam-letter"
                    style={{
                      animation:
                        "1.3s ease-in 0.32s 1 normal forwards running realSangria-blurred-letters",
                    }}
                  >
                    l
                  </span>
                  <span
                    className="vamtam-letter"
                    style={{
                      animation:
                        "1.3s ease-in 0.1s 1 normal forwards running realSangria-blurred-letters",
                    }}
                  >
                    a
                  </span>
                  <span
                    className="vamtam-letter"
                    style={{
                      animation:
                        "1.3s ease-in 0.1s 1 normal forwards running realSangria-blurred-letters",
                    }}
                  >
                    v
                  </span>
                  <span
                    className="vamtam-letter"
                    style={{
                      animation:
                        "1.3s ease-in 0s 1 normal forwards running realSangria-blurred-letters",
                    }}
                  >
                    o
                  </span>
                  <span
                    className="vamtam-letter"
                    style={{
                      animation:
                        "1.3s ease-in 0s 1 normal forwards running realSangria-blurred-letters",
                    }}
                  >
                    r
                  </span>
                  <span
                    className="vamtam-letter"
                    style={{
                      animation:
                        "1.3s ease-in 0s 1 normal forwards running realSangria-blurred-letters",
                    }}
                  >
                    s
                  </span>
                </span>
              </h1>
              <div
                className="realSangria-background-image"
                style={{
                  transform: `scale(${offsetY * 0.0001 + 1})`,
                  animation:
                    "1s ease-in 0s 1 normal forwards running realSangria-blurred-letters",
                }}
              ></div>
            </div>
            <div
              className="realSangria-heroOverlay"
              style={{
                animation:
                  "1s ease-in 0s 1 normal forwards running realSangria-blurred-letters",
              }}
            ></div>
          </div>
        </section>
        <div
          className="waveimage-container"
          style={{
            animation: "1s ease-in 2s 1 normal forwards running fadeIn",
          }}
        >
          <img src={WineWave3} className="winevavw1" alt="wine wave" />
          <img src={WineWave4} className="winevavw2" alt="wine wave" />
        </div>
        <section className="realSangria-Keypoint-wrapper">
          <div className="realSangria-container">
            <div className="realSangria-keyPoints">
              <div className="realSangria-keyPoints-inner">
                <div className="realSangria-keyPoints-inner-one">
                  <h2
                    ref={animatedRef}
                    className={`animated-element ${isVisible ? "animate" : ""}`}
                  >
                    01.
                  </h2>
                </div>
                <div className="realSangria-keyPoints-inner-two">
                  <h4
                    ref={animatedRef}
                    className={`animated-element ${isVisible ? "animate" : ""}`}
                  >
                    natural fruit
                    <br />
                    flavors
                  </h4>
                  {/* <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p> */}
                </div>
              </div>
              <div className="realSangria-keyPoints-inner">
                <div className="realSangria-keyPoints-inner-one">
                  <h2
                    ref={animatedRef}
                    className={`animated-element ${isVisible ? "animate" : ""}`}
                  >
                    02.
                  </h2>
                </div>
                <div className="realSangria-keyPoints-inner-two">
                  <h4
                    ref={animatedRef}
                    className={`animated-element ${isVisible ? "animate" : ""}`}
                  >
                    7.5% - 8%
                    <br />
                    alcohol
                  </h4>
                  {/*  <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p> */}
                </div>
              </div>
              <div className="realSangria-keyPoints-inner">
                <div className="realSangria-keyPoints-inner-one">
                  <h2
                    ref={animatedRef}
                    className={`animated-element ${isVisible ? "animate" : ""}`}
                  >
                    03.
                  </h2>
                </div>
                <div className="realSangria-keyPoints-inner-two">
                  <h4
                    ref={animatedRef}
                    className={`animated-element ${isVisible ? "animate" : ""}`}
                  >
                    all weather
                    <br />
                    Wines
                  </h4>
                  {/* <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p> */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Herosection;
