import React, { useState } from "react";
import "./hipRecipes.css";
import PageHeroSection from "../components/pageHeroSection";
import hipToshipBackground from "../images/hipToship-Background.webp";
import backgroundheadding from "../images/backgroundheadding.png";
import ModelImage from "../components/modelImage";

import REALANTIGUO from "../images/574483343b31e8-300x269.jpg";
import REALANTIGUO1 from "../images/RA.jpg";

import BLACKBERRYSANGRIRINHA from "../images/582c9071a8e45.png-300x269.jpeg";
import BLACKBERRYSANGRIRINHA1 from "../images/BS.jpg";

import LYCHEECLARETE from "../images/574483440d9a16-300x269.jpg";
import LYCHEECLARETE1 from "../images/LC.jpg";

import TRADITIONALSANGRIA from "../images/5744834cb1bcb5-300x269.jpg";
import TRADITIONALSANGRIA1 from "../images/TS.jpg";

import GUAVAMINTSANGRIA from "../images/5744835435d974-300x269.jpg";
import GUAVAMINTSANGRIA1 from "../images/GMS@2x.jpg";

import PINEAPPLECOBBLER from "../images/5744830b499273-1-240x300.jpg";
import PINEAPPLECOBBLER1 from "../images/PC.jpg";

import HIGHOCTANE from "../images/574482f6dc5eb2-300x224.jpg";
import HIGHOCTANE1 from "../images/HIGH.jpg";

import JEREZEDA from "../images/574482e1cf34e1-240x300.png";
import JEREZEDA1 from "../images/JEREZADA.jpg";

import video1 from "../images/real-sangria-cava-scaled.jpg";
import video2 from "../images/real-sangria-spritz-scaled.jpg";
import video3 from "../images/real-sangria-swirl-scaled.jpg";

import Footer from "../components/footer";
import ModelVideo from "../components/modelVideo";
import AnimatedElement from "../components/AnimatedElement";
import { Helmet } from "react-helmet";

function HipRecipes() {
  const headdingSpans = (
    <>
      <span className="vamtam-word">
        <span
          className="vamtam-letter blurred"
          style={{
            animation:
              "1.3s ease-in 0.2s 1 normal forwards running realSangria-blurred-letters",
          }}
        >
          I
        </span>
        <span
          className="vamtam-letter"
          style={{
            animation:
              "1.3s ease-in 0s 1 normal forwards running realSangria-blurred-letters",
          }}
        >
          t'
        </span>
        <span
          className="vamtam-letter"
          style={{
            animation:
              "1.3s ease-in 0s 1 normal forwards running realSangria-blurred-letters",
          }}
        >
          s
        </span>
      </span>
      <span className="vamtam-word">
        <span
          className="vamtam-letter"
          style={{
            animation:
              "1.3s ease-in 0s 1 normal forwards running realSangria-blurred-letters",
          }}
        >
          H
        </span>
        <span
          className="vamtam-letter blurred"
          style={{
            animation:
              "1.3s ease-in 0.32s 1 normal forwards running realSangria-blurred-letters",
          }}
        >
          i
        </span>
        <span
          className="vamtam-letter "
          style={{
            animation:
              "1.3s ease-in 0s 1 normal forwards running realSangria-blurred-letters",
          }}
        >
          p
        </span>
      </span>
      <span className="vamtam-word">
        <span
          className="vamtam-letter "
          style={{
            animation:
              "1.3s ease-in 0s 1 normal forwards running realSangria-blurred-letters",
          }}
        >
          T
        </span>
        <span
          className="vamtam-letter"
          style={{
            animation:
              "1.3s ease-in 0s 1 normal forwards running realSangria-blurred-letters",
          }}
        >
          o
        </span>
      </span>
      <span className="vamtam-word">
        <span
          className="vamtam-letter blurred"
          style={{
            animation:
              "1.3s ease-in 0.27s 1 normal forwards running realSangria-blurred-letters",
          }}
        >
          S
        </span>
        <span
          className="vamtam-letter"
          style={{
            animation:
              "1.3s ease-in 0s 1 normal forwards running realSangria-blurred-letters",
          }}
        >
          i
        </span>
        <span
          className="vamtam-letter"
          style={{
            animation:
              "1.3s ease-in 0s 1 normal forwards running realSangria-blurred-letters",
          }}
        >
          p
        </span>
      </span>
    </>
  );
  const item = {
    backimage: hipToshipBackground,
    hedding: headdingSpans,
  };

  const video1url = (
    <iframe
      src="https://www.youtube.com/embed/OoC1S3H8Si4?si=Segfi0xf1nLKo8LS"
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowfullscreen
    ></iframe>
  );
  const video1ur2 = (
    <iframe
      src="https://www.youtube.com/embed/da8GRD-SH6A?si=DaGb5_ZG9VbFE2IN"
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowfullscreen
    ></iframe>
  );
  const video1ur3 = (
    <iframe
      src="https://www.youtube.com/embed/15iFYyIk0KE?si=FTaXCSdoKqeDnYnD"
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowfullscreen
    ></iframe>
  );
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [selectedheaddingname, setSelectedheaddingname] = useState("");

  const [modalIsOpenVideo, setModalIsOpenVideo] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState("");

  function openModalImage(imageUrl, headdingname) {
    setSelectedImage(imageUrl);
    setSelectedheaddingname(headdingname);
    setModalIsOpen(true);
  }
  function closeModalIMage() {
    setModalIsOpen(false);
  }
  function openModalVideo(videoUrl) {
    setSelectedVideo(videoUrl);
    setModalIsOpenVideo(true);
  }
  function closeModalVideo() {
    setModalIsOpenVideo(false);
  }
  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <title>Real Sangria - Cruz Garcia</title>
      </Helmet>
      <PageHeroSection item={item} />
      <div className="OurSangria-main-headding-wrapper">
        <div className="realSangria-container">
          <div className="OurSangria-detailes-headding">
            <p className="smallheadding">Product Range</p>
            <h2 className="animated-headding">
              <span className="vamtam-word">
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1.12s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  R
                </span>
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  e
                </span>
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  c
                </span>
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1.33s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  i
                </span>
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1.1s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  p
                </span>
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1.1s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  e
                </span>
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  s
                </span>
              </span>
              <span className="vamtam-word">
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1.22s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  &
                </span>
              </span>
              <span className="vamtam-word">
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  S
                </span>
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  e
                </span>
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1.09s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  r
                </span>
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1.32s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  v
                </span>
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  i
                </span>
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  n
                </span>
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1.23s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  g
                </span>
              </span>
              <span className="vamtam-word">
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1.29s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  S
                </span>
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  u
                </span>

                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  gg
                </span>
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1.36s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  es
                </span>
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  t
                </span>
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  i
                </span>
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  o
                </span>
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  n
                </span>
                <span
                  className="vamtam-letter blurred"
                  style={{
                    animation:
                      "1.3s ease-in 1s 1 normal forwards running realSangria-blurred-letters",
                  }}
                >
                  s
                </span>
              </span>
            </h2>
            <img
              src={backgroundheadding}
              className="background-hedding-image"
              alt="headding images"
            />
            <p className="text-center">
              Reál Sangria is ready to drink straight from the bottle. Simply
              chill and savor the refreshing taste!
            </p>
            {/* <h4>Do & Sip</h4> */}
          </div>
        </div>
      </div>
      <div className="hipRecipes-wrapper">
        <div className="realSangria-container">
          {/*  <div className="hipRecipes-videos">
            <div className="hipRecipes-videos-inner">
              <AnimatedElement className="animate-image-hipsip-left">
                <div className="hipRecipes-videos-inner-image left">
                  <img
                    src={video1}
                    alt="real sangria cava scaled"
                    onClick={() => openModalVideo(video1url)}
                  />
                </div>
              </AnimatedElement>
              <AnimatedElement className="animate-fadein">
                <p>Real Sangria Swirl</p>
              </AnimatedElement>
            </div>
            <div className="hipRecipes-videos-inner">
              <AnimatedElement className="animate-image-hipsip-right">
                <div className="hipRecipes-videos-inner-image right">
                  <img
                    src={video2}
                    alt="real sangria cava scaled"
                    onClick={() => openModalVideo(video1ur2)}
                  />
                </div>
              </AnimatedElement>
              <AnimatedElement className="animate-fadein">
                <p>Real Sangria Spritz</p>
              </AnimatedElement>
            </div>
            <div className="hipRecipes-videos-inner">
              <AnimatedElement className="animate-image-hipsip-right">
                <div className="hipRecipes-videos-inner-image right">
                  <img
                    src={video3}
                    alt="real sangria cava scaled"
                    onClick={() => openModalVideo(video1ur3)}
                  />
                </div>
              </AnimatedElement>
              <AnimatedElement className="animate-fadein">
                <p>Real Sangria Cava</p>
              </AnimatedElement>
            </div>
          </div> */}
          <div className="hipRecipes-images">
            <div className="hipRecipes-images-inner">
              <AnimatedElement className="animate-image-hipsip-left">
                <div className="hipRecipes-images-inner-image left">
                  <img
                    src={REALANTIGUO1}
                    alt="REAL ANTIGUO"
                    onClick={() => openModalImage(REALANTIGUO, "REAL ANTIGUO")}
                  />
                </div>
              </AnimatedElement>
              <AnimatedElement className="animate-fadein">
                <p>REAL ANTIGUO</p>
              </AnimatedElement>
            </div>
            <div className="hipRecipes-images-inner">
              <AnimatedElement className="animate-image-hipsip-right">
                <div className="hipRecipes-images-inner-image right">
                  <img
                    src={BLACKBERRYSANGRIRINHA1}
                    alt="BLACKBERRY SANGRIRINHA"
                    onClick={() =>
                      openModalImage(
                        BLACKBERRYSANGRIRINHA,
                        "BLACKBERRY SANGRIRINHA"
                      )
                    }
                  />
                </div>
              </AnimatedElement>
              <AnimatedElement className="animate-fadein">
                <p>BLACKBERRY SANGRIRINHA</p>
              </AnimatedElement>
            </div>
            <div className="hipRecipes-images-inner">
              <AnimatedElement className="animate-image-hipsip-right">
                <div className="hipRecipes-images-inner-image right">
                  <img
                    src={LYCHEECLARETE1}
                    alt="LYCHEE CLARETE"
                    onClick={() =>
                      openModalImage(LYCHEECLARETE, "LYCHEE CLARETE")
                    }
                  />
                </div>
              </AnimatedElement>
              <AnimatedElement className="animate-fadein">
                <p>LYCHEE CLARETE</p>
              </AnimatedElement>
            </div>
            <div className="hipRecipes-images-inner">
              <AnimatedElement className="animate-image-hipsip-left">
                <div className="hipRecipes-images-inner-image left">
                  <img
                    src={TRADITIONALSANGRIA1}
                    alt="TRADITIONAL SANGRIA"
                    onClick={() =>
                      openModalImage(TRADITIONALSANGRIA, "TRADITIONAL SANGRIA")
                    }
                  />
                </div>
              </AnimatedElement>
              <AnimatedElement className="animate-fadein">
                <p>TRADITIONAL SANGRIA</p>
              </AnimatedElement>
            </div>
            <div className="hipRecipes-images-inner">
              <AnimatedElement className="animate-image-hipsip-right">
                <div className="hipRecipes-images-inner-image right">
                  <img
                    src={GUAVAMINTSANGRIA1}
                    alt="GUAVA MINT SANGRIA"
                    onClick={() =>
                      openModalImage(GUAVAMINTSANGRIA, "GUAVA MINT SANGRIA")
                    }
                  />
                </div>
              </AnimatedElement>
              <AnimatedElement className="animate-fadein">
                <p>GUAVA MINT SANGRIA</p>
              </AnimatedElement>
            </div>
            <div className="hipRecipes-images-inner">
              <AnimatedElement className="animate-image-hipsip-left">
                <div className="hipRecipes-images-inner-image left">
                  <img
                    src={PINEAPPLECOBBLER1}
                    alt="PINEAPPLE COBBLER"
                    onClick={() =>
                      openModalImage(PINEAPPLECOBBLER, "PINEAPPLE COBBLER")
                    }
                  />
                </div>
              </AnimatedElement>
              <AnimatedElement className="animate-fadein">
                <p>PINEAPPLE COBBLER</p>
              </AnimatedElement>
            </div>
            <div className="hipRecipes-images-inner">
              <AnimatedElement className="animate-image-hipsip-right">
                <div className="hipRecipes-images-inner-image right">
                  <img
                    src={HIGHOCTANE1}
                    alt="HIGH OCTANE"
                    onClick={() => openModalImage(HIGHOCTANE, "HIGH OCTANE")}
                  />
                </div>
              </AnimatedElement>
              <AnimatedElement className="animate-fadein">
                <p>HIGH OCTANE</p>
              </AnimatedElement>
            </div>
            <div className="hipRecipes-images-inner">
              <AnimatedElement className="animate-image-hipsip-left">
                <div className="hipRecipes-images-inner-image left">
                  <img
                    src={JEREZEDA1}
                    alt="JEREZEDA"
                    onClick={() => openModalImage(JEREZEDA, "JEREZEDA")}
                  />
                </div>
              </AnimatedElement>
              <AnimatedElement className="animate-fadein">
                <p>JEREZEDA</p>
              </AnimatedElement>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <ModelImage
        isOpen={modalIsOpen}
        closeModalIMage={closeModalIMage}
        imageUrl={selectedImage}
        headding={selectedheaddingname}
      />
      <ModelVideo
        isOpenVideo={modalIsOpenVideo}
        closeModalVideo={closeModalVideo}
        videoiframe={selectedVideo}
      />
    </>
  );
}

export default HipRecipes;
