import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import "./model.css";

function ModelVideo({ isOpenVideo, closeModalVideo, videoiframe }) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalIsOpening, setModalIsOpening] = useState(false);

  useEffect(() => {
    if (isOpenVideo) {
      setModalIsOpen(true);
      setTimeout(() => setModalIsOpening(true), 500);
    } else {
      setModalIsOpening(false);
      setTimeout(() => setModalIsOpen(false), 500);
    }
  }, [isOpenVideo]);

  function afterClose() {
    if (!modalIsOpen) {
      // Ensure a smooth transition for the overlay
      document.body.style.overflow = "";
    }
  }

  return (
    <>
      <div>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModalVideo}
          onAfterClose={afterClose}
          contentLabel="Example Modal"
          closeTimeoutMS={300}
          className={
            modalIsOpening
              ? "modal-transition-here videomodel open"
              : "modal-transition-here videomodel"
          }
        >
          <div className="model-wrapper">
            <button onClick={closeModalVideo} className="close-button">
              &times;
            </button>
          </div>
          <div className="model-video-wrapper">{videoiframe}</div>
        </Modal>
      </div>
    </>
  );
}

export default ModelVideo;
